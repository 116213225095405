import Skeleton from '@mui/material/Skeleton';
import { Link} from 'react-router-dom';

function productSkeleton() {
  return (
    <Link className="border inline-block no-underline group mx-1 md:mx-0 rounded-xl pb-1 cursor-pointer hover:shadow-lg transition-easy-in-out duration-300 dark:border-amber-400">
    <div className="rounded-xl relative bg-gray-50 border-b overflow-hidden flex justify-center items-center">
        <Skeleton className='w-full h-full md:h-64 ' animation="wave" variant="rectangular" height={250} />
    </div>
    <div className='px-3 py-2'>
        <Skeleton className='my-3 w-2/3' animation="wave" variant="rectangular" height={20} />
        <Skeleton className='w-1/3' animation="wave" variant="rectangular" height={20} />
    </div>
</Link>
  )
}

export default productSkeleton