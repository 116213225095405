import React, { useEffect } from 'react';
import Productdetailswiper from '../components/Productdetailswiper';
import Productinfo from '../components/Productinfo';
import Similiarproducts from '../components/Similiarproducts';
import Newproducts from '../components/Newproducts';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProduct } from '../features/productSlice';
import { Helmet } from 'react-helmet';

const Productdetail = () => {
  const params = useParams();
  const product = useSelector((state) => state.product)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProduct(params.id))
  }, [dispatch, params.id])
  return (
    <div className='py-12 dark:bg-zinc-900'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Məhsulun iç səhifəsi | Light Studio Baku</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className='w-[90%] mx-auto flex flex-col md:flex-row min-h-[60vh]'>
        <Productdetailswiper product={product} />
        <Productinfo product={product} />
      </div>
      <div className='w-[90%] mx-auto h-[1px] bg-zinc-300 my-12' />
      <Similiarproducts product={product} />
      <Newproducts />
    </div>
  )
}

export default Productdetail