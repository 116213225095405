import React, { useEffect } from 'react'
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineInstagram, AiFillMail } from 'react-icons/ai';
import { BsWhatsapp, BsFillTelephoneFill } from 'react-icons/bs';
import { GoClock } from 'react-icons/go';
import { GiLetterBomb } from 'react-icons/gi';
import { BiCurrentLocation } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Contact = () => {
    const categories = useSelector((state) => state.categories)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCategories())
    }, [dispatch])
    return (
        <div className='dark:bg-zinc-900'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Əlaqə | Light Studio Baku</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
            {categories.loading &&
                <div className='h-[60vh] w-full flex flex-col justify-center items-center' role="status">
                    <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>}
            {!categories.loading && categories.categories.settings && categories.categories.settings.map((setting) => (
                <div className='w-[90%] mx-auto py-10'>
                    <h1 className='montserrat font-semibold border-b border-amber-500 px-2 pb-2 mb-6 text-xs md:text-base dark:text-white'>Bizimlə Əlaqə</h1>
                    <div className='flex flex-col md:flex-row md:justify-between'>
                        <ul className='flex flex-col justify-center cursor-pointer border border-amber-500 rounded-lg w-full px-5 py-3 mb-6 md:mb-0 md:w-[30%]'>
                            <li className='group dark:text-white'><a href={setting.facebook} className='cursor-pointer flex items-center text-sm leading-7 group-hover:text-amber-500'><FaFacebookF className='mr-2' /><span>Facebook</span></a></li>
                            <li className='group dark:text-white'><a href={setting.instagram} className='cursor-pointer flex items-center text-sm leading-7 group-hover:text-amber-500'><AiOutlineInstagram className='mr-2' /><span>Instagram</span></a></li>
                            <li className='group dark:text-white'><a href={`https://wa.me/${setting.nomre}`} className='cursor-pointer flex items-center text-sm leading-7 group-hover:text-amber-500'><BsWhatsapp className='mr-2' /><span>WhatsApp  </span></a></li>
                            <li className='group cursor-pointer flex items-center text-sm leading-7 dark:text-white'><BiCurrentLocation className='mr-2 group-hover:text-amber-500' /><span className='group-hover:text-amber-500'>{setting.unvan}</span></li>
                            <li className='group cursor-pointer flex items-center text-sm leading-7 dark:text-white'><BsFillTelephoneFill className='mr-2 group-hover:text-amber-500' /><span className='group-hover:text-amber-500'>{setting.nomre}</span></li>
                            <li className='group cursor-pointer flex items-center text-sm leading-7 dark:text-white'><GoClock className='mr-2 group-hover:text-amber-500' /><span className='group-hover:text-amber-500'>{setting.work}</span></li>
                            <li className='group cursor-pointer flex items-center text-sm leading-7 dark:text-white'><AiFillMail className='mr-2 group-hover:text-amber-500' /><span className='group-hover:text-amber-500'>{setting.email}</span></li>
                        </ul>
                        <div className='border border-amber-400 montserrat w-full h-60 bg-zinc-800 rounded-xl flex flex-col items-center justify-center text-amber-400 pt-8 pb-4 px-3 md:mb-0 md:w-[65%]'>
                            <h1 className='text-5xl font-semibold tracking-widest'>LIGHT</h1>
                            <h2 className='text-xl tracking-widest'>STUDIO BAKU</h2>
                            <h3 className='text-xl mt-3 mb-2'>Design Group</h3>
                            <div className='flex flex-wrap flex-row justify-center w-full'>
                                <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><BsFillTelephoneFill /></span><span>{setting.nomre}</span></p>
                                <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><AiOutlineInstagram /></span><span>{setting.name}</span></p>
                                <p className='text-[10px] flex items-center'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><GiLetterBomb /></span><span>{setting.email}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default Contact