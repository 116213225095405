import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice';
import { Navigate } from 'react-router-dom';

function Brands() {
  const categories = useSelector((state) => state.categories)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])
  return (
    <>
      {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
      {categories.loading && <li className='h-full cursor-pointer group'>Zəhmət olmasa gözləyin...</li>}
      {!categories.loading && categories.categories.brands && categories.categories.brands[0] !== undefined
        ?
        <div className="bg-gray-100 py-10 dark:bg-zinc-900">
          <h1 className="text-center mb-8 text-2xl montserrat font-semibold dark:text-white">Dünya brendlərinin Azərbaycandakı rəsmi distribütoru</h1>
          <Swiper
            modules={[Pagination, Autoplay, Navigation]}
            className="w-[90%] mx-auto"
            simulateTouch={true}
            speed={2000}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4.5,
                spaceBetween: 30,
              },
              1440: {
                slidesPerView: 6.5,
                spaceBetween: 30,
              }
            }}
          >
            {categories.categories.brands[0].map((brand) => (
              <SwiperSlide className="w-72 h-64" key={brand.id}>
                <div className="group cursor-pointer w-full h-full border border-gray-300 rounded-lg bg-white pt-2 flex flex-col justify-center items-center rounded overflow-hidden">
                  <div className="h-32 w-[95%] flex justify-center items-center overflow-hidden">
                    <img className="scale-50 mb-2 object-cover group-hover:scale-90" src={brand?.url} alt="pic" />
                  </div>
                  <p className="montserat font-medium w-full h-12 bg-amber-500 flex justify-center items-center text-white hover:bg-zinc-900">{brand?.name}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        : null}
    </>
  );
}
export default Brands;