import React from 'react';
import { Link } from 'react-router-dom';
import errorImage from '../assets/not-found.webp';
import { Helmet } from 'react-helmet';

const Error = () => {
    return (
        <div className='py-4'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Xəta | Light Studio Baku</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className='w-[90%] mx-auto flex flex-col-reverse md:flex-row'>
                <div className='w-full flex flex-col items-center justify-center md:w-1/2'>
                    <h1 className='hidden cursor-pointer text-xl h-12 w-48 rounded-lg bg-zinc-800 md:flex items-center justify-center md:text-2xl'><span className='mr-2 text-white'>Light</span> <span className='text-amber-400'>Studio</span></h1>
                    <h2 className='text-6xl montserrat font-semibold mt-4 mb-2'>404</h2>
                    <h3 className='text-xl text-center montserrat font-medium md:text-2xl'>Axtarışınıza uyğun nəticə tapılmadı!</h3>
                    <Link className='cursor-pointer text-xl h-12 w-48 rounded-lg bg-zinc-800 flex items-center justify-center hover:bg-zinc-900 text-white mt-4 transition-easy-in-out duration-300' to='/'>Əsas səhifəyə qayıt</Link>
                </div>
                <div className='w-full flex justify-center items-center md:w-1/2 '>
                    <img className='w-32 md:w-auto' src={errorImage} alt='errorImage' />
                </div>
            </div>
        </div>
    )
}

export default Error;