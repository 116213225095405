import React, { useEffect } from 'react'
import { useContext, Maincontext } from "../context";
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice';

const Sidebar = () => {
    const { sidebar, setSidebar } = useContext(Maincontext);
    const closeSidebar = () => {
        setSidebar(false);
        document.getElementsByTagName("body")[0].style.overflow = "scroll";
    }
    const categories = useSelector((state) => state.categories);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);
    return (
        <div className={`${sidebar ? 'translate-x-0' : 'translate-x-full'} fixed top-[22vh] z-50 left-0 w-full h-[75vh] bg-white overflow-scroll pb-16 transition-easy-in-out duration-300`}>
            <ul>
                {!categories.loading && <li className='bg-amber-400'>
                    <Link onClick={closeSidebar} className='text-base py-6 block w-[90%] mx-auto montserrat font-semibold tracking-wider flex items-center dark:text-white' to='/'>Şirkət</Link>
                    <div className='bg-white'>
                        <ul className='montserrat'>
                            <li onClick={closeSidebar}><Link className='py-3 px-[5%] block w-full text-sm cursor-pointer dark:bg-zinc-900 dark:text-white' to='/aboutcompany'>Haqqımızda</Link></li>
                            <li onClick={closeSidebar}><Link className='py-3 px-[5%] block w-full text-sm cursor-pointer dark:bg-zinc-900 dark:text-white' to='/stores'>Mağazalar</Link></li>
                            <li onClick={closeSidebar}><Link className='py-3 px-[5%] block w-full text-sm cursor-pointer dark:bg-zinc-900 dark:text-white' to='/contact'>Əlaqə</Link></li>
                        </ul>
                    </div>
                </li>}
                {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
                {categories.loading && <li className='h-full cursor-pointer group'><Link to='/' className='px-4 h-full text-amber-400 montserrat font-semibold tracking-wider text-[10px] flex items-center hover:bg-zinc-800 md:text-xs md:px-8 rounded-full transition-easy-in-out duration-300'>Zəhmət olmasa gözləyin...</Link></li>}
                {!categories.loading && categories.categories.cats?.length && categories.categories.cats.map((category) => (
                    <li className='bg-amber-400' key={category.id}>
                        <Link onClick={closeSidebar} className='text-base py-6 block w-[90%] mx-auto montserrat font-semibold tracking-wider flex items-center dark:text-white' to={{ pathname: '/mehsullar', search: `page=category%2F${category.name.toLowerCase()}&pag=1&type=6` }}>{category.name}</Link>
                        <div className='bg-white'>
                            <ul className='montserrat'>
                                {category.sub.map(categorySub => (
                                    <li key={categorySub.id} onClick={closeSidebar}><Link className='py-3 px-[5%] block w-full cursor-pointer dark:bg-zinc-900 dark:text-white' to={{ pathname: '/mehsullar', search: `page=category%2F${category.name.toLowerCase()}%2F${categorySub.name.toLowerCase()}&pag=1&type=6` }}>{categorySub.name}</Link></li>
                                ))}
                            </ul>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Sidebar