import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
    loading: false,
    searchdatas: [],
    error: "",
};
export const fetchSearchdatas = createAsyncThunk("searchdatas/fetchSearchdatas", async (searchVal) => {
    const response = await axios
        .get(`https://api.lightstudio.az/api_cat1.php?page=search/${searchVal}`);
    return response.data;
});
const searchSlice = createSlice({
    name: "searchdatas",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSearchdatas.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchSearchdatas.fulfilled, (state, action) => {
            state.loading = false;
            state.searchdatas = action.payload;
            state.error = "";
        });
        builder.addCase(fetchSearchdatas.rejected, (state, action) => {
            state.loading = false;
            state.searchdatas = [];
            state.error = action.error.message;
        });
    },
});
export default searchSlice.reducer;