import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {useSelector} from 'react-redux'
import './pagination.scss'
import {useSearchParams, useLocation} from "react-router-dom";

export default function BasicPagination() {
    const paginationCount = useSelector((state) =>state.productFilter.paginationCount)
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = +searchParams.get('pag')
    const location = useLocation();

    const handleChange = (event, page)=>{
      const sp = new URLSearchParams(location.search)
      sp.set("pag", page)
      setSearchParams(sp)
      window.scrollTo(0,0)
    }
  return (
      <Stack spacing={2}>
        <Pagination count={paginationCount} page={currentPage} color="primary" onChange={handleChange}/>
      </Stack>
  );
}