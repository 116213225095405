import { useState, useEffect } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { useSearchParams, useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategories } from "../features/categoriesSlice";
import { fetchProductFilter } from "../features/productFilterSlice";
import { useDebouncedCallback } from 'use-debounce';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-5 w-5 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}
export default function AccordionProducts() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const categoriesName = searchParams.get('page') && searchParams.get('page').split("/")[1]
    const SubCategories = searchParams.get('page') && searchParams.get('page').split("/")[2]
    const query = location.search
    const categories = useSelector((state) => state.categories);
    const dispatch = useDispatch();
    const [minPrice, setMinPrice] = useState(searchParams.get('min') || '')
    const [maxPrice, setMaxPrice] = useState(searchParams.get('max') || '')
    useEffect(() => {
        dispatch(fetchCategories())
        dispatch(fetchProductFilter(query))
        setMinPrice(searchParams.get('min') || '')
        setMaxPrice(searchParams.get('max') || '')
    }, [dispatch, query, searchParams]);
    useEffect(() => {
        const sp = new URLSearchParams(location.search)
        if (!sp.has("page")) {
            sp.append("page", "category/cilciraqlar")
            setSearchParams(sp)
        } if (!sp.has("pag")) {
            sp.append("pag", 1)
            setSearchParams(sp)
        } if (!sp.has("type")) {
            sp.append("type", 6)
            setSearchParams(sp)
        }
    }, [location.search, setSearchParams])
    const [open, setOpen] = useState(1);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const priceMin = (event) => {
        const sp = new URLSearchParams(location.search)
        if (event.target.value > 0) {
            sp.set("min", event.target.value)
            setSearchParams(sp)
        } else if (event.target.value === 0 || event.target.value.trim('') === '') {
            sp.delete("min")
            setSearchParams(sp)
        }
    }
    const debouncedPriceMin = useDebouncedCallback(
        priceMin,
        500
    );
    const setMin = (event) => {
        setMinPrice(event.target.value)
    }
    const priceMax = (event) => {
        const sp = new URLSearchParams(location.search)
        if (event.target.value > 0) {
            sp.set("max", event.target.value)
            setSearchParams(sp)
        } else if (event.target.value === 0 || event.target.value.trim('') === '') {
            sp.delete("max")
            setSearchParams(sp)
        }
    }
    const setMax = (event) => {
        setMaxPrice(event.target.value)
    }
    const debouncedPriceMax = useDebouncedCallback(
        priceMax,
        500
    );
    function setPageCategory(data) {
        const sp = new URLSearchParams(location.search)
        sp.set("page", `category/${data}`)
        setSearchParams(sp)
    }
    function setPageSubCategory(data) {
        const sp = new URLSearchParams(location.search)
        let saveCategory = sp.get("page").split("/")[1]
        sp.set("page", `category/${saveCategory}/${data}`)
        setSearchParams(sp)
    }

    return (
        <div className="rounded overflow-hidden">
            {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
            {categories.loading &&
                <div className='h-[20vh] w-full flex flex-col justify-center items-center' role="status">
                    <svg aria-hidden="true" className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            }
            {!categories.loading && categories.categories.cats &&
                <>
                    <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                        <AccordionHeader className="bg-zinc-800 text-white px-3" onClick={() => handleOpen(1)}>
                            <h1 className="text-base">Kategoriyalar</h1>
                        </AccordionHeader>
                        {!categories.loading && categories.categories.cats && categories.categories.cats.map((category) => (
                            <AccordionBody key={category.id} className="bg-amber-900 text-white px-3 h-full" onClick={_ => setPageCategory(category.name.toLowerCase())}>
                                <div className="relative flex items-center mb-3 border-b pb-4 cursor-pointer" >
                                    <input
                                        readOnly
                                        className="relative before rounded appearance-none w-5 h-5 border mr-2 cursor-pointer checked:bg-amber-500 checked:border-0 peer"
                                        id={category.id}
                                        type="checkbox"
                                        checked={category.name.toLowerCase() === categoriesName ? true : false}
                                    />
                                    <label className="tracking-wider montserrat cursor-pointer peer-checked:text-amber-500 peer-checked:font-medium" htmlFor={category.id}>{category.name}</label>
                                </div>
                            </AccordionBody>
                        ))}
                    </Accordion>
                    <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                        <AccordionHeader className="bg-zinc-800 text-white px-3" onClick={() => handleOpen(1)}>
                            <h1 className="text-base">Alt Kategoriyalar</h1>
                        </AccordionHeader>
                        {!categories.loading && categories.categories.cats && categories.categories.cats.map((category) => (
                            category.name.toLowerCase() === categoriesName ? category.sub.map(categorySub => (
                                <AccordionBody key={categorySub.id} className="bg-amber-900 text-white px-3 h-full" onClick={_ => setPageSubCategory(categorySub.name.toLowerCase())}>
                                    <div className="relative flex items-center mb-3 border-b pb-4 cursor-pointer">
                                        <input
                                            readOnly
                                            className="relative before rounded appearance-none w-5 h-5 border mr-2 cursor-pointer checked:bg-amber-500 checked:border-0 peer"
                                            id={categorySub.id}
                                            type="checkbox"
                                            checked={categorySub.name.toLowerCase() === SubCategories ? true : false}
                                        />
                                        <label className="tracking-wider montserrat cursor-pointer peer-checked:text-amber-500 peer-checked:font-medium" htmlFor={categorySub.id}>{categorySub.name}</label>
                                    </div>
                                </AccordionBody>
                            )) : null
                        ))}
                    </Accordion>
                    <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                        <AccordionHeader className="bg-zinc-800 text-white px-3" onClick={() => handleOpen(2)}>
                            <h1 className="text-base">Qiymət</h1>
                        </AccordionHeader>
                        <AccordionBody className="bg-amber-900 px-3">
                            <form onSubmit={(e) => e.preventDefault()} autoComplete='off' className='h-12 grid gap-2 grid-cols-2 border-b rounded'>
                                <label className='h-full rounded cursor-pointer flex justify-center items-center text-lg overflow-hidden' htmlFor="search">
                                    <input
                                        className='outline-none w-[90%] h-full text-center bg-amber-400 placeholder-black'
                                        id="search"
                                        type="number"
                                        placeholder="min"
                                        onChange={e => { debouncedPriceMin(e); setMin(e) }}
                                        value={minPrice}
                                        min={0}
                                    />
                                    <span className="h-full w-[40%] bg-zinc-900 flex items-center justify-center  text-white montserrat font-medium px-2">Azn</span>
                                </label>
                                <label className='h-full rounded cursor-pointer flex justify-center items-center text-lg  overflow-hidden' htmlFor="search">
                                    <input
                                        className='outline-none w-[60%] h-full text-center bg-amber-400 placeholder-black'
                                        id="search"
                                        type="number"
                                        placeholder="max"
                                        onChange={e => { debouncedPriceMax(e); setMax(e) }}
                                        value={maxPrice}
                                        min={0}
                                    />
                                    <span className="h-full w-[40%] bg-zinc-900 flex items-center justify-center text-white montserrat font-medium px-2">Azn</span>
                                </label>
                            </form>
                        </AccordionBody>
                    </Accordion>
                </>
            }
        </div>
    );
}