import React, { useEffect } from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import ReactWhatsapp from 'react-whatsapp';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice';

const GotoWhatsApp = () => {
    const categories = useSelector((state) => state.categories)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCategories())
    }, [dispatch])
    return (
        <>
            {!categories.loading && categories?.categories?.settings && categories?.categories?.settings?.map((setting) => (
                <ReactWhatsapp key={setting.id} className='animate-pulse fixed bottom-10 right-[5%] z-50 w-16 h-16 bg-green-600 text-white text-2xl flex justify-center items-center rounded-2xl cursor-pointer md:w-20 md:h-20 md:text-5xl' number={setting?.nomre} message="Salam! " >
                    <AiOutlineWhatsApp />
                </ReactWhatsapp>
            ))}
        </>
    )
}
export default GotoWhatsApp;