import React, { useState } from 'react';
import { AiOutlineWeibo, AiTwotoneHeart } from 'react-icons/ai';
import { useLocalStorage } from 'usehooks-ts';
import { Navigate } from 'react-router-dom';
import toast from 'react-hot-toast'
const ProductInfo = ({ product }) => {
    const [favoritesLocal, setFavoritesLocal] = useLocalStorage('favorites', [])
    const [favorites, setFavorites] = useState(favoritesLocal || [])
    const setItemLocalstrorage = (product) => {
        const unique = favorites.every((item) => item.id !== product.id)
        if (unique) {
            favorites.push(product)
            setFavorites(favorites)
            setFavoritesLocal(favorites)
            toast.success('Məhsul favorilərə əlavə edildi!')
        }
    }
    return (
        <div className='montserrat w-full md:w-1/2 flex justify-center items-center'>
            {!product.loading && product.error ? <Navigate to="/dontvork" replace={true} /> : null}
            {product.loading && null}
            {!product.loading && product.product.product &&
                <div className='w-full h-[90%]'>
                    <div className='mt-4 md:mt-0'>
                        {Number(product?.product?.product?.endirim) !== 0 && <p className='w-full md:w-auto inline-flex items-center bg-yellow-800 px-4 py-1 text-white rounded mr-4 my-1 md:my-0'><AiOutlineWeibo className='mr-1' /><span>Endirimli Məhsul</span></p>}
                        {product?.product?.product?.new === 1 ? <p className='w-full md:w-auto inline-flex items-center bg-pink-800 text-white px-4 py-1 rounded mr-4 my-1 md:my-0'><AiOutlineWeibo className='mr-1' /><span>Yeni Məhsul</span></p> : null}
                    </div>
                    <h2 className='my-3 dark:text-white'>
                        <span className='text-xl font-medium mr-3 md:text-2xl'>Məhsulun adı:</span>
                        <span className='text-lg'>{product?.product?.product?.name}</span>
                    </h2>
                    {Number(product?.product?.product?.endirim) !== 0
                        ?
                        <p className='text-[10px] italic flex flex-wrap items-center md:text-xl dark:text-white'><span>Bu məhsul üçün</span> <span className='h-4 rounded bg-green-600 mx-2 px-2 text-white flex justify-center items-center md:h-8'>{product?.product?.product?.endirim} %</span> <span> endirim nəzərdə tutulmuşdur.</span></p>
                        :
                        <p className='text-[10px] text-yellow-800 dark:text-rose-600 md:text-xs'>Bu məhsul hal-hazırda endirimdə deyil!</p>}
                    <div className='w-full h-[1px] bg-zinc-300 my-4' />
                    <div className='flex items-center mb-3'>
                        <span className='text-[10px] font-medium mr-8 dark:text-white md:text-sm'>Rəng çeşidləri:</span>
                        <div className='flex items-center'>
                            {product.product.product.colors.map((color, index) => (
                                <span key={index} style={{ backgroundColor: color }} className='inline-block w-5 h-5 mr-3 rounded-lg cursor-pointer md:w-7 md:h-7'></span>
                            ))}
                        </div>
                    </div>
                    <table>
                        <tbody className='flex items-center jusify-center'>
                            <tr className='flex flex-col'>
                                <td className='text-[10px] font-medium mr-3 mb-3 dark:text-white md:text-sm md:mr-8'>Məhsulun Ölçüsü:</td>
                                <td className='text-[10px] font-medium mr-3 mb-3 dark:text-white md:text-sm md:mr-8'>Məhsulun Qiyməti:</td>
                                {Number(product?.product?.product?.endirim) ? <td className='text-[10px] font-medium mr-3 dark:text-white md:text-sm md:mr-8'>Endirimli Qiyməti:</td> : null}
                            </tr>
                            {product?.product?.product?.sizes.map((size, index) => (
                                <tr key={index} className='flex flex-col md:items-center'>
                                    <td className='text-[10px] font-medium mr-2 mb-3 text-yellow-600 md:mr-8 md:text-sm'>{size.size}</td>
                                    {Number(product?.product?.product?.endirim) ? <td className='text-[10px] font-medium mr-2 mb-3 line-through text-red-600 md:text-sm md:mr-8'>{size.price} AZN</td> : <td className='text-[10px] font-medium mr-8 mb-2 text-green-600 dark:text-white md:text-sm'>{size.price} AZN</td>}
                                    {Number(product?.product?.product?.endirim) ? <td className='text-[10px] font-medium mr-2 text-green-600 md:text-sm md:mr-8'>{size.price - (product?.product?.product?.endirim * size.price / 100)} AZN</td> : null}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='w-full h-[1px] bg-zinc-300 my-4' />
                    <h3 className='tracking-wider my-3 dark:text-white flex flex-col'>
                        <span className='text-xl font-medium mr-3 md:text-xl'>Məhsul haqqında:</span>
                        <span className='text-sm'>{product?.product?.product?.text}</span>
                    </h3>
                    <button onClick={() => setItemLocalstrorage(product?.product?.product)} className='bg-rose-600 flex justify-center items-center text-white px-4 py-2 w-full rounded-lg md:py-3 md:mt-8 md:ml-auto md:w-auto md:justify-start'><AiTwotoneHeart className='mr-2' /><span>Favorilerimə əlavə et</span></button>
                </div>
            }
        </div>
    )
}
export default ProductInfo;