import React, { useEffect } from 'react';
import truck from '../assets/truck.png';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice';
import { Navigate } from 'react-router-dom';
import DeliverySkeleton from './DeliverySkeleton';

const Deliverymodal = () => {
    const categories = useSelector((state) => state.categories)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCategories())
    }, [dispatch])
    return (
        <div className='h-20 bg-amber-500 md:h-12'>
            {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
            {categories.loading && <DeliverySkeleton />}
            {!categories.loading && categories.categories.settings && categories.categories.settings.map((setting) => (
                <div key={setting.id} className='w-[90%] mx-auto h-full flex py-2 flex-col md:flex-row md:items-center md:justify-between'>
                    <div className='h-full w-full pr-6 flex items-center overflow-hidden md:w-[57%] 2xl:w-[70%]'>
                        <img className='truckAnimation h-3/5' src={truck} alt='truck' />
                    </div>
                    {Number(setting.free) !== 0 ? <p className='montserrat text-xs text-center'>
                        <strong>{setting.free} AZN</strong>
                        <span> və üzəri sifarişlərinizdə </span>
                        <strong> Bakı </strong>
                        <span> şəhəri ərazisinə </span>
                        <strong> ÖDƏNİŞSİZ ÇATDIRILMA </strong>
                    </p> : <p className='montserrat text-xs text-center'><strong> Bakı </strong>
                        <span> şəhəri ərazisinə </span>
                        <strong> ÖDƏNİŞSİZ ÇATDIRILMA </strong></p>}
                </div>
            ))}
        </div>
    )
}

export default Deliverymodal