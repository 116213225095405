import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../style/productdetailswiper.css";
import { Autoplay, FreeMode, Navigation, Thumbs } from "swiper";
import { Navigate } from 'react-router-dom';
import ProductDetailsSkeleton from '../components/Productdetailskeleton'

export default function App({ product }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <div className="w-full md:w-1/2 mr-10">
            <Swiper
                style={{
                    "--swiper-navigation-color": "rgb(218, 165, 32)",
                    "--swiper-pagination-color": "rgb(218, 165, 32)",
                }}
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                simulateTouch={true}
                speed={1000}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                className="mySwiper2"
            >
                {!product.loading && product.error ? <li>Xəta Baş Verdi</li> : null}
                {product.loading &&
                    <div className='flex justify-between'>
                        <ProductDetailsSkeleton />
                    </div>
                }
                {!product.loading && product?.product?.product &&
                    product?.product?.product?.images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img className="w-full rounded-lg h-[12rem] md:h-auto md:w-auto" src={image} alt='pic' />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                simulateTouch={true}
                speed={1000}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                className="mySwiper"
            >
                {!product.loading && product.error ? <Navigate to="/dontvork" replace={true} /> : null}
                {product.loading && null}
                {!product.loading && product?.product?.product &&
                    product?.product?.product?.images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img className="w-full rounded-lg h-[6rem] md:h-[10rem]" src={image} alt='pic' />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}
