import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineSearch, AiTwotoneHeart } from 'react-icons/ai';
import azerbaijanFlag from '../assets/azerbaijanFlag.png';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { BsFillTelephoneFill, BsLightbulb, BsLightbulbOff } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { GiLetterBomb, GiCrossMark } from 'react-icons/gi';
import { useContext, Maincontext } from "../context";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice';
import { useLocalStorage } from 'usehooks-ts'

const Header = () => {
    const [value, setValue] = useState('');
    const { sidebar, setSidebar, theme, setTheme } = useContext(Maincontext);
    const categories = useSelector((state) => state.categories);
    const dispatch = useDispatch();
    const [favoritesLocal] = useLocalStorage('favorites');
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);
    const openSidebar = () => {
        setSidebar(true);
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    const closeSidebar = () => {
        setSidebar(false);
        document.getElementsByTagName("body")[0].style.overflow = "scroll";
    }
    const handleThemeSwitch = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }
    const searchFilter = (e) => {
        e.preventDefault()
        if (value.trim().length !== 0) {
            navigate(`/search/${value.trim()}`);
        }
        setValue('');
    }
    return (
        <div className='md:border-0 dark:bg-zinc-900 pb-4 md:pb-0'>
            <div className='w-[90%] h-[12vh] md:h-[16vh] mx-auto flex items-center justify-between'>
                <form onSubmit={searchFilter} autoComplete='off' className='hidden items-center w-[30%] h-12 md:flex border-b-2 border-amber-500 rounded relative'>
                    <label onClick={searchFilter} className='w-16 h-full rounded bg-amber-500 cursor-pointer flex justify-center items-center text-2xl' htmlFor="search">
                        <AiOutlineSearch className="search-icon" />
                    </label>
                    <input
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        className='z-20 outline-none w-[90%] h-full p-6 peer bg-transparent dark:text-white'
                        id="search"
                        type="text"
                        required
                        maxLength={70}
                    />
                    <span className='z-10 montserrat absolute left-20 peer-valid:z-0 peer-valid:-translate-y-6 peer-valid:text-amber-400 peer-valid:translate-x-7 peer-valid:text-center peer-valid:bg-zinc-900 peer-valid:w-1/2 peer-valid:py-[2px] peer-valid:px-2 peer-valid:text-[10px] peer-valid:rounded peer-valid:tracking-widest ease-in-out duration-300 dark:text-white dark:text-white dark:peer-valid:text-white dark:peer-valid:bg-amber-500'>Axtariş edin...</span>
                </form>
                <Link to='/' className='cursor-pointer text-xl w-2/5 h-12 rounded-lg bg-zinc-800 flex items-center justify-center md:w-[30%] md:text-2xl dark:bg-white'><span className='mr-2 text-white dark:text-black'>Light</span> <span className='text-amber-400 dark:text-amber-500'>Studio</span></Link>
                <div className='rounded h-12 flex justify-center items-center md:w-[30%] md:justify-end md:border-b-2 md:border-amber-500'>
                    {theme === 'light' && <div onClick={handleThemeSwitch} className='mx-1 md:mx-3 cursor-pointer rounded-xl w-10 h-10 flex justify-center items-center bg-amber-500 text-white'><BsLightbulb /></div>}
                    {theme === 'dark' && <div onClick={handleThemeSwitch} className='mx-1 md:mx-3 cursor-pointer rounded-xl w-10 h-10 flex justify-center items-center bg-amber-500 text-white'><BsLightbulbOff /></div>}
                    <img className='mx-1 md:mx-3 w-[25px] h-[25px] object-cover cursor-pointer md:w-[35px] md:h-[35px]' src={azerbaijanFlag} alt='azerbaijanFlag' />
                    <Link to='/favorites' className='relative cursor-pointer'><AiTwotoneHeart className='text-red-600 text-xl mx-1 md:mx-3 md:text-4xl' />
                        <span className='text-[10px] absolute -top-1 right-[2px] md:top-0 md:right-2 text-white bg-black w-[15px] h-[15px] rounded-full flex justify-center items-center dark:bg-white dark:text-black'>{favoritesLocal ? favoritesLocal.length : 0}</span>
                    </Link>
                </div>
                <div className='flex justify-end md:hidden'>{!sidebar ? <GiHamburgerMenu className='text-xl animate-pulse dark:text-white' onClick={openSidebar} /> : <GiCrossMark className='text-xl animate-pulse dark:text-white' onClick={closeSidebar} />}</div>
            </div>
            <form onSubmit={searchFilter} autoComplete='off' className='flex items-center w-[90%] mx-auto h-12 md:flex border border-amber-400 rounded relative md:hidden'>
                <label onClick={searchFilter} className='w-16 h-full rounded bg-amber-400 cursor-pointer flex justify-center items-center text-2xl' htmlFor="search">
                    <AiOutlineSearch className="search-icon" />
                </label>
                <input
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    className='z-20 outline-none w-[90%] h-full p-6 peer bg-transparent dark:text-white'
                    id="search"
                    type="text"
                    required
                    maxLength={70}
                />
                <span className='z-10 montserrat absolute left-20 peer-valid:-translate-y-6 dark:text-white dark:peer-valid:text-white dark:peer-valid:bg-amber-500 peer-valid:text-amber-400 peer-valid:translate-x-7 peer-valid:text-center peer-valid:bg-zinc-900 peer-valid:w-1/2 peer-valid:py-[2px] peer-valid:px-2 peer-valid:text-[10px] peer-valid:rounded peer-valid:tracking-widest ease-in-out duration-300'>Axtariş edin...</span>
            </form>
            <div className='hidden border-t border-b border-transparent md:flex bg-zinc-900 dark:border-t dark:border-b dark:border-amber-500'>
                <div className='w-[90%] h-[8vh] mx-auto overflowScrollHide'>
                    <ul className='w-[100%] h-full flex items-center'>
                        {!categories.loading &&
                            <li className='h-full cursor-pointer group'>
                                <Link className='px-4 h-full text-amber-400 montserrat font-semibold tracking-wider text-[10px] flex items-center hover:bg-zinc-800 md:text-xs md:px-8 rounded-full transition-easy-in-out duration-300' to='/'>Şirkət</Link>
                                <div className="border-t border-b border-amber-400 invisible opacity-0 absolute left-0 w-full z-10 bg-zinc-800 group-hover:visible group-hover:opacity-90 forTransitionClass">
                                    <div className='w-[90%] mx-auto flex justify-between py-4 '>
                                        <ul className='montserrat leading-7'>
                                            <li className='text-sm text-amber-400 border-b border-transparent hover:border-b hover:border-amber-400 hover:bg-zinc-900 rounded transition-easy-in-out duration-300'><Link className='inline-block p-2' to='/aboutcompany'>Haqqımızda</Link></li>
                                            <li className='text-sm text-amber-400 border-b border-transparent hover:border-b hover:border-amber-400 hover:bg-zinc-900 rounded transition-easy-in-out duration-300'><Link className='inline-block p-2' to='/stores'>Mağazalar</Link></li>
                                            <li className='text-sm text-amber-400 border-b border-transparent hover:border-b hover:border-amber-400 hover:bg-zinc-900 rounded transition-easy-in-out duration-300'><Link className='inline-block p-2' to='/contact'>Əlaqə</Link></li>
                                        </ul>
                                        <div className='border border-amber-400 montserrat w-full h-60 bg-zinc-800 rounded-xl flex flex-col items-center justify-center text-amber-400 pt-8 pb-4 px-3 md:mb-0 md:w-[40%]'>
                                            <h1 className='text-5xl font-semibold tracking-widest'>LIGHT</h1>
                                            <h2 className='text-xl tracking-widest'>STUDIO BAKU</h2>
                                            <h3 className='text-xl mt-3 mb-2'>Design Group</h3>
                                            <div className='flex flex-wrap flex-row justify-center w-full'>
                                                <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><BsFillTelephoneFill /></span><span>+994992909898</span></p>
                                                <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><AiFillInstagram /></span><span>lightstudiobaku</span></p>
                                                <p className='text-[10px] flex items-center'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><GiLetterBomb /></span><span>lightstudiobaku@gmail.com</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        }
                        {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
                        {categories.loading && <li className='h-full cursor-pointer group'><Link to='/' className='px-4 h-full text-amber-400 montserrat font-semibold tracking-wider text-[10px] flex items-center hover:bg-zinc-800 md:text-xs md:px-8 rounded-full transition-easy-in-out duration-300'>Zəhmət olmasa gözləyin...</Link></li>}
                        {!categories.loading && categories.categories.cats && categories.categories.cats.map((category) => (
                            <li key={category.id} className='h-full cursor-pointer group'>
                                <Link to={{ pathname: '/mehsullar', search: `page=category%2F${category.name.toLowerCase()}&pag=1&type=6` }} className='px-4 h-full text-amber-400 montserrat font-semibold tracking-wider text-[10px] flex items-center hover:bg-zinc-800 md:text-xs md:px-8 rounded-full transition-easy-in-out duration-300'>{category.name}</Link>
                                {category.sub.length !== 0
                                    ?
                                    <div className="border-t border-b border-amber-400 hidden absolute left-0 w-full z-10 bg-zinc-800 group-hover:block">
                                        <div className='w-[90%] mx-auto flex py-4 '>
                                            <div className='w-[60%] flex'>
                                                <ul className='montserrat leading-7 mr-5 w-36'>
                                                    {category.sub.slice(0, 6).map(categorySub => (
                                                        <Link key={categorySub.id} to={{ pathname: '/mehsullar', search: `page=category%2F${category.name.toLowerCase()}%2F${categorySub.name.toLowerCase()}&pag=1&type=6` }}><li className='text-sm text-amber-400 border-b border-transparent p-2 hover:border-b hover:border-amber-400 hover:bg-zinc-900 rounded transition-easy-in-out duration-300'>{categorySub.name}</li></Link>
                                                    ))}
                                                </ul>
                                                <ul className='montserrat leading-7 mr-5 w-36'>
                                                    {category.sub.slice(6, 12).map(categorySub => (
                                                        <Link key={categorySub.id} to={{ pathname: '/mehsullar', search: `page=category%2F${category.name.toLowerCase()}%2F${categorySub.name.toLowerCase()}&pag=1&type=6` }}><li className='text-sm text-amber-400 border-b border-transparent p-2 hover:border-b hover:border-amber-400 hover:bg-zinc-900 rounded transition-easy-in-out duration-300'>{categorySub.name}</li></Link>
                                                    ))}
                                                </ul>
                                                <ul className='montserrat leading-7 mr-5 w-36'>
                                                    {category.sub.slice(12, 18).map(categorySub => (
                                                        <Link key={categorySub.id} to={{ pathname: '/mehsullar', search: `page=category%2F${category.name.toLowerCase()}%2F${categorySub.name.toLowerCase()}&pag=1&type=6` }}><li className='text-sm text-amber-400 border-b border-transparent p-2 hover:border-b hover:border-amber-400 hover:bg-zinc-900 rounded transition-easy-in-out duration-300'>{categorySub.name}</li></Link>
                                                    ))}
                                                </ul>
                                                <ul className='montserrat leading-7 mr-5 w-36'>
                                                    {category.sub.slice(21, 27).map(categorySub => (
                                                        <Link key={categorySub.id} to={{ pathname: '/mehsullar', search: `page=category%2F${category.name.toLowerCase()}%2F${categorySub.name.toLowerCase()}&pag=1&type=6` }}><li className='text-sm text-amber-400 border-b border-transparent p-2 hover:border-b hover:border-amber-400 hover:bg-zinc-900 rounded transition-easy-in-out duration-300'>{categorySub.name}</li></Link>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className='border border-amber-400 self-end montserrat w-full h-60 bg-zinc-800 rounded-xl flex flex-col items-center justify-center text-amber-400 pt-8 pb-4 px-3 md:mb-0 md:w-[40%]'>
                                                <h1 className='text-5xl font-semibold tracking-widest'>LIGHT</h1>
                                                <h2 className='text-xl tracking-widest'>STUDIO BAKU</h2>
                                                <h3 className='text-xl mt-3 mb-2'>Design Group</h3>
                                                <div className='flex flex-wrap flex-row justify-center w-full'>
                                                    <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><BsFillTelephoneFill /></span><span>+994992909898</span></p>
                                                    <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><AiFillInstagram /></span><span>lightstudiobaku</span></p>
                                                    <p className='text-[10px] flex items-center'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><GiLetterBomb /></span><span>lightstudiobaku@gmail.com</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default Header;