import React from 'react';
import { GiCrossMark } from 'react-icons/gi';
import { AiOutlineWeibo } from 'react-icons/ai';
import { Maincontext, useContext } from '../context';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";

const ProductModal = () => {
    const { setShowProductModal, productModal } = useContext(Maincontext);
    const closeProductModal = () => {
        setShowProductModal(false)
    }
    return (
        <div className='productModalShadow w-full'>
            <div className='z-50 rounded w-3/5 h-[60vh] bg-gray-300 fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 z-50 flex'>
                <GiCrossMark onClick={closeProductModal} className='cursor-pointer absolute top-3 right-4 text-indigo-500' />
                <Swiper
                    spaceBetween={30}
                    simulateTouch={true}
                    speed={1000}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false
                    }}
                    modules={[Pagination, Autoplay, Navigation]}
                    className="mySwiper12 w-[45%] h-full p-2"
                >
                    {productModal?.images.map((image, index) => (
                        <SwiperSlide key={index} className='p-2'>
                            <img className='w-full h-full object-cover' src={image} alt='img' />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className='w-[55%] h-full pl-6 pr-4 py-8'>
                    <div className='mt-0'>
                        {Number(productModal.endirim) !== 0 && <p className='w-auto inline-flex items-center bg-yellow-800 px-4 py-1 text-white rounded mr-4 my-0 text-xs'><AiOutlineWeibo className='mr-1' /><span>Endirimli Məhsul</span></p>}
                        <p className='w-auto inline-flex items-center bg-pink-800 text-white px-4 py-1 rounded mr-4 my-0 text-xs'><AiOutlineWeibo className='mr-1' /><span>Yeni Məhsul</span></p>
                    </div>
                    <h2 className='my-3'>
                        <span className='text-xl font-medium mr-3 '>Məhsulun adı:</span>
                        <span className='text-lg'>{productModal.name}</span>
                    </h2>
                    {Number(productModal.endirim) !== 0
                        ?
                        <p className='text-xs italic flex flex-wrap items-center'><span>Bu məhsul üçün</span> <span className='rounded bg-green-600 mx-2 px-2 text-white flex justify-center items-center h-6'>{productModal.endirim} %</span> <span> endirim nəzərdə tutulmuşdur.</span></p>
                        :
                        <p className='text-xs text-yellow-800 text-xs'>Bu məhsul hal-hazırda endirimdə deyil!</p>}
                    <div className='w-full h-[1px] bg-zinc-300 my-3' />
                    <div className='flex items-center mb-3'>
                        <span className='text-xs font-medium mr-8'>Rəng çeşidləri:</span>
                        <div className='flex items-center'>
                            {productModal.colors.map((color, index) => (
                                <span key={index} style={{ backgroundColor: color }} className='inline-block w-3 h-3 mr-3 rounded-lg cursor-pointer'></span>
                            ))}
                        </div>
                    </div>
                    <table>
                        <tbody className='flex items-center jusify-center'>
                            <tr className='flex flex-col'>
                                <td className='text-[10px] font-medium mr-8 mb-3'>Məhsulun Ölçüsü:</td>
                                <td className='text-[10px] font-medium mr-8 mb-3'>Məhsulun Qiyməti:</td>
                                {Number(productModal.endirim) ? <td className='text-[10px] font-medium mr-8'>Endirimli Qiyməti:</td> : null}
                            </tr>
                            {productModal.sizes.map((size, index) => (
                                <tr key={index} className='flex flex-col'>
                                    <td className='text-[10px] font-medium mr-8 mb-3 text-yellow-600'>{size.size}</td>
                                    {Number(productModal.endirim) ? <td className='text-[10px] font-medium mr-8 mb-3 line-through text-red-600'>{size.price} AZN</td> : <td className='text-xs font-medium mr-8 mb-2 text-green-600'>{size.price} AZN</td>}
                                    {Number(productModal.endirim) ? <td className='text-[10px] font-medium mr-8 text-green-600'>{size.price - (productModal.endirim * size.price / 100)} AZN</td> : null}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='w-full h-[1px] bg-zinc-300 my-2' />
                    <h3 className='my-3'>
                        <span className='font-medium mr-3'>Məhsul haqqında:</span>
                        <span className='text-xs'>{productModal.text.length > 165 ? productModal.text.slice(0, 165).concat(' ...') : productModal.text}</span>
                    </h3>
                    <Link className='absolute bottom-4 right-4 text-xs text-indigo-800 italic underline' onClick={closeProductModal} to={`/productdetail/${productModal.id}`}>əftarlı bax</Link>
                </div>
            </div>
        </div>
    )
}

export default ProductModal