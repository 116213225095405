import React from 'react';
import { TbTruckDelivery } from 'react-icons/tb';
import { MdChangeCircle } from 'react-icons/md'

const Advantages = () => {
  return (
    <div className='py-12 dark:bg-zinc-900'>
      <div className='w-[90%] mx-auto flex flex-col md:flex-row md:justify-between'>
        <div className='w-full flex flex-col items-center my-3 bg-gray-200 p-6 rounded-xl cursor-pointer transition-easy-in-out duration-300 md:w-[48%] md:my-0'>
          <TbTruckDelivery className='text-5xl text-amber-400' />
          <h3 className='montserrat font-semibold mt-6 mb-4 text-2xl'>Çatdırılma</h3>
          <p className='montserrat text-center tracking-wide'>
            Bakı şəhəri daxilində istənilən ünvana çatdırılma “Light Studio” MMC hesabına həyata keçirilir.
            Daimi müştərilərimizə Bakıdan kənar ünvanlara ən münasib şərtlərlə çatdırma etməyə çalışırıq.
          </p>
        </div>
        <div className='w-full flex flex-col items-center my-3 bg-gray-200 p-6 rounded-xl cursor-pointer transition-easy-in-out duration-300 md:w-[48%] md:my-0'>
          <MdChangeCircle className='text-5xl text-amber-400' />
          <h3 className='montserrat font-semibold mt-6 mb-4 text-2xl'>Zəmanət</h3>
          <p className='montserrat text-center tracking-wide'>
            Keyfiyyətinə əmin olmadığımız məhsul əsla satmırıq. “Light Studio” MMC olaraq, bütün məhsullarımıza 3 il zəmanət veririk.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Advantages