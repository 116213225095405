import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { AiOutlineBuild } from 'react-icons/ai';
import ProductsLoader from './ProductsLoader';
import { Link } from 'react-router-dom'
import { useNavigate, Navigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useLocalStorage } from 'usehooks-ts';
import toast from 'react-hot-toast';
import { BsSearch } from 'react-icons/bs';
import { Maincontext, useContext } from '../context';

const Bestseller = ({ categories }) => {
    const { setProductModal, setShowProductModal } = useContext(Maincontext);
    const navigate = useNavigate()
    const [favoritesLocal, setFavoritesLocal] = useLocalStorage('favorites', [])
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const checkInFavorite = (id) => {
        const unique = favoritesLocal.some((item) => id === item.id)
        if (unique) {
            return true
        } else {
            return false
        }
    }
    const favoriteClick = (e, product) => {
        if (e) {
            favoritesLocal.push(product)
            setFavoritesLocal(favoritesLocal)
            toast.success('Məhsul favorilərə əlavə edildi!')
        } else {
            const newArray = favoritesLocal.filter(item => item.id !== product.id)
            setFavoritesLocal(newArray)
            toast.error('Məhsul favorilərdən çıxarıldı!')
        }
    }
    const getProductDetail = (id) => {
        navigate({
            pathname: `/productdetail/${id}`,
        });
    }
    const openProductModal = (prod) => {
        setShowProductModal(true);
        setProductModal(prod);
    }
    return (
        <div className='py-12 dark:bg-zinc-900'>
            <div className='w-[90%] mx-auto'>
                <div className='flex w-[95%] justify-between mx-auto mb-3 md:w-full md:mb-5'>
                    <h2 className='text-sm md:text-xl dark:text-white'>Ən çox baxılan məhsullar</h2>
                </div>
                <Swiper
                    modules={[Pagination, Autoplay, Navigation]}
                    simulateTouch={true}
                    speed={1000}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        },
                        1440: {
                            slidesPerView: 5,
                            spaceBetween: 30
                        }
                    }}
                >
                    {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
                    {categories.loading &&
                        <div className='flex justify-between'>
                            <ProductsLoader />
                            <ProductsLoader className="hidden md:flex" />
                            <ProductsLoader className="hidden md:flex" />
                            <ProductsLoader className="hidden md:flex" />
                        </div>
                    }
                    {!categories.loading && categories.categories.mostview && categories.categories.mostview.map((sell) => (
                        <SwiperSlide key={sell.id}>
                            <div onClick={() => { getProductDetail(sell.id) }} className="relative border w-[95%] inline-block no-underline group mx-1 md:mx-0 rounded-xl pb-1 cursor-pointer hover:shadow-lg transition-easy-in-out duration-300 dark:border-amber-400 md:w-full">
                                <div className="rounded-xl h-60 relative bg-gray-50 border-b overflow-hidden flex justify-center items-center">
                                    <img className='w-full h-full group-hover:scale-110 transition-easy-in-out duration-300' src={sell?.images[0]} alt="img" />
                                    <div className='bg-white w-8 h-8 rounded-full flex justify-center items-center absolute top-3 right-3'>
                                        <Checkbox {...label} onClick={(event) => { favoriteClick(event.target.checked, sell); event.stopPropagation() }} icon={<FavoriteBorder />} checkedIcon={<Favorite />} checked={checkInFavorite(sell.id)} sx={{ '&.Mui-checked': { color: "red" } }} />
                                    </div>
                                    {sell.new === 1 ? <div className='bg-white w-36 h-8 rounded-full flex px-4 items-center absolute top-3 left-3 bg-amber-500 text-white'><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Yeni Məhsul</span></div> : null}
                                    {Number(sell.endirim) !== 0 && <div className={`${sell.new === 1 ? 'top-12' : 'top-3'} bg-white w-36 h-8 rounded-full flex items-center absolute px-4 left-3 bg-rose-700 text-white`}><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Endirimli Məhsul</span></div>}
                                </div>
                                <Link to={`/productdetail/${sell.id}`}>
                                    <div className='px-3 py-2'>
                                        <h1 className='border-b text-sm montserrat font-semibold dark:text-white'>{sell?.name.length >= 30 ? sell?.name.slice(0, 30).concat('  ...') : sell?.name}</h1>
                                        <p className='mt-1 h-16 leading-5 text-xs overflow-x-auto pr-2 overflowScrollHide dark:text-white'>{sell?.text}</p>
                                        <div className='my-2 w-full h-[1px] bg-gray-200'></div>
                                        <div className='flex justify-between'>
                                            <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-amber-400 text-white mr-2'>{sell?.sizes[0].size}</span>
                                            {Number(sell.endirim) === 0 ?
                                                <span className='text-lg rounded w-full h-7 flex justify-center items-center text-xs bg-green-500 text-white'>{sell?.sizes[0].price} ₼</span> :
                                                <>
                                                    <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-red-700 text-white line-through mr-2'>{sell?.sizes[0].price} ₼</span>
                                                    <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-green-500 text-white'>{sell?.sizes[0].price - sell?.sizes[0].price * Number(sell.endirim) / 100} ₼</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Link>
                                <div onClick={(event) => { openProductModal(sell); event.stopPropagation() }} className='hidden opacity-0 absolute top-20 left-1/2 -translate-x-2/4 bg-gray-300 w-20 h-20 rounded-full md:flex justify-center items-center group-hover:opacity-90'>
                                    <BsSearch />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Bestseller