import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { GiLetterBomb } from 'react-icons/gi';
import chandelier from '../assets/chandelier.png'

const Advertising = () => {
    return (
        <div className='py-12 dark:bg-zinc-900'>
            <div className='w-[90%] mx-auto flex flex-col md:flex-row md:justify-between'>
                <div className='montserrat w-full bg-zinc-800 rounded-xl flex items-center justify-evenly text-amber-400 pt-8 pb-4 px-3 mb-6 md:mb-0 md:w-[48%]'>
                    <div className='flex flex-col items-center'>
                        <h1 className='text-5xl font-semibold tracking-widest'>LIGHT</h1>
                        <h2 className='text-xl tracking-widest'>STUDIO BAKU</h2>
                        <h3 className='text-xl mt-3 mb-2'>Design Group</h3>
                    </div>
                    <div>
                        <img className='w-40' src={chandelier} alt='pic' />
                    </div>
                </div>
                <div className='montserrat w-full bg-zinc-800 rounded-xl flex flex-col items-center justify-center text-amber-400 pt-8 pb-4 px-3 md:mb-0 md:w-[48%]'>
                    <h1 className='text-5xl font-semibold tracking-widest'>LIGHT</h1>
                    <h2 className='text-xl tracking-widest'>STUDIO BAKU</h2>
                    <h3 className='text-xl mt-3 mb-2'>Design Group</h3>
                    <div className='flex flex-wrap flex-row justify-center w-full'>
                        <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><BsFillTelephoneFill /></span><span>+994555449999</span></p>
                        <p className='text-[10px] flex items-center mr-2 mb-2 md:mb-0'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><AiFillInstagram /></span><span>lightstudiobaku</span></p>
                        <p className='text-[10px] flex items-center'><span className='flex items-center justify-center w-4 h-4 text-[8px] bg-amber-400 mr-1 text-black rounded-full'><GiLetterBomb /></span><span>lightstudiobaku@gmail.com</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advertising