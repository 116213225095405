import React from "react"
import ContentLoader from "react-content-loader"

const ProductsLoader = (props) => (
    <ContentLoader
        speed={2}
        width={400}
        height={124}
        viewBox="0 0 400 124"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="3" y="4" rx="0" ry="0" width="407" height="66" />
        <rect x="25" y="36" rx="0" ry="0" width="1" height="1" />
        <rect x="21" y="38" rx="0" ry="0" width="1" height="0" />
        <rect x="27" y="27" rx="0" ry="0" width="1" height="1" />
        <rect x="4" y="105" rx="0" ry="0" width="57" height="12" />
        <rect x="65" y="118" rx="0" ry="0" width="1" height="0" />
        <rect x="5" y="91" rx="3" ry="3" width="178" height="6" />
        <rect x="4" y="76" rx="3" ry="3" width="178" height="6" />
        <rect x="73" y="105" rx="0" ry="0" width="57" height="12" />
        <rect x="145" y="105" rx="0" ry="0" width="57" height="12" />
    </ContentLoader>
)

export default ProductsLoader