import React from 'react';
import EntrySlider from '../components/EntrySlider'
import Bestseller from '../components/Bestseller';
import Newproducts from '../components/Newproducts';
import Advertising from '../components/Advertising';
import Advantages from '../components/Advantages';
import Brands from '../components/Brands';
import Discounted from '../components/Discounted';
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice'
import { useEffect } from 'react';
const Home = () => {
    const categories = useSelector((state) => state.categories);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCategories)
    }, [dispatch])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Light Studio Baku</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <EntrySlider categories={categories} />
            <Bestseller categories={categories} />
            <Newproducts categories={categories} />
            <Discounted categories={categories} />
            <Advertising />
            <Advantages />
            <Brands />
        </div>
    )
}
export default Home