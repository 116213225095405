import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineBuild, AiTwotoneHeart } from 'react-icons/ai';
import { useLocalStorage } from 'usehooks-ts'
import toast from 'react-hot-toast';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { Helmet } from 'react-helmet';
import { BsSearch } from 'react-icons/bs';
import { Maincontext, useContext } from '../context';
const Products = () => {
    const { setProductModal, setShowProductModal } = useContext(Maincontext);
    const [favoritesLocal, setFavoritesLocal] = useLocalStorage('favorites', [])
    const navigate = useNavigate()
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const checkInFavorite = (id) => {
        const unique = favoritesLocal.some((item) => id === item.id)
        if (unique) {
            return true
        } else {
            return false
        }
    }
    const favoriteClick = (e, product) => {
        if (e) {
            favoritesLocal.push(product)
            setFavoritesLocal(favoritesLocal)
            toast.success('Məhsul favorilərə əlavə edildi!')
        } else {
            const newArray = favoritesLocal.filter(item => item.id !== product.id)
            setFavoritesLocal(newArray)
            toast.error('Məhsul favorilərdən çıxarıldı!')
        }
    }
    const getProductDetail = (id) => {
        navigate({
            pathname: `/productdetail/${id}`,
        });
    }
    const openProductModal = (prod) => {
        setShowProductModal(true);
        setProductModal(prod);
    }
    return (
        <div className='py-6 md:py-12 dark:bg-zinc-900'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bəyəndikləriniz | Light Studio Baku</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            {favoritesLocal.length > 0
                ?
                <h2 className='text-xl font-medium mb-4 text-center montserrat md:text-3xl md:mb-8 dark:text-white'>Seçilmiş məhsullar | ({favoritesLocal.length} ədəd)</h2>
                :
                <div className='w-[95%] mx-auto h-[55vh] flex flex-col items-center justify-center md:w-1/2'>
                    <div className='bg-gray-300 w-12 h-12 rounded-full flex justify-center items-center'><AiTwotoneHeart className='text-red-600' /></div>
                    <h3 className='montserrat text-lg font-semibold text-amber-500 my-2 text-center'>Favoriler siyahınız hal-hazırda boşdur!</h3>
                    <p className='montserrat text-xs font-medium text-center mb-3 dark:text-white md:text-base'>Favoriler siyahınızda məhsul tapılmadı. “Alışverişe Başla” düyməsinə sıxaraq istədiyiniz məhsulları favorilər siyahısına əlavə edə bilərsiniz.</p>
                    <Link to='/' className='montserrattext-xs bg-amber-500 text-white px-4 py-3 rounded-lg'>Alışverişe Başla!</Link>
                </div>}
            <div className='w-[90%] mx-auto grid gap-2 grid-cols-1 md:grid-cols-4 md:gap-7'>
                {favoritesLocal.length > 0 ?
                    favoritesLocal.map((favory) => (
                        <div onClick={() => { getProductDetail(favory.id) }} key={favory.id} className='relative border w-[95%] inline-block no-underline group mx-1 md:mx-0 rounded-xl pb-1 cursor-pointer hover:shadow-lg transition-easy-in-out duration-300 dark:border-amber-400 md:w-full'>
                            <div className="rounded-xl h-60 relative bg-gray-50 border-b overflow-hidden flex justify-center items-center">
                                <img className='w-full h-full group-hover:scale-110 transition-easy-in-out duration-300' src={favory?.images[0]} alt="img" />
                                <div className='bg-white w-8 h-8 rounded-full flex justify-center items-center absolute top-3 right-3'>
                                    <Checkbox {...label} onClick={(event) => { favoriteClick(event.target.checked, favory); event.stopPropagation() }} icon={<FavoriteBorder />} checkedIcon={<Favorite />} checked={checkInFavorite(favory.id)} sx={{ '&.Mui-checked': { color: "red" } }} />
                                </div>
                                {favory.new === 1 ? <div className='bg-white w-36 h-8 rounded-full flex px-4 items-center absolute top-3 left-3 bg-amber-500 text-white'><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Yeni Məhsul</span></div> : null}
                                {Number(favory.endirim) !== 0 && <div className={`${favory.new === 1 ? 'top-12' : 'top-3'} bg-white w-36 h-8 rounded-full flex items-center absolute px-4 left-3 bg-rose-700 text-white`}><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Endirimli Məhsul</span></div>}
                            </div>
                            <div className='px-3 py-2'>
                                <h1 className='border-b text-sm montserrat font-semibold dark:text-white'>{favory?.name.length >= 30 ? favory?.name.slice(0, 30).concat('  ...') : favory?.name}</h1>
                                <p className='mt-1 h-16 leading-5 text-xs overflow-x-auto pr-2 overflowScrollHide dark:text-white'>{favory?.text}</p>
                                <div className='my-2 w-full h-[1px] bg-gray-200'></div>
                                <div className='flex justify-between'>
                                    <span className='text-lg rounded w-full h-7 flex justify-center items-center text-sm bg-amber-400 text-white mr-2'>{favory?.sizes[0].size}</span>
                                    {Number(favory.endirim) === 0 ?
                                        <span className='text-lg rounded w-full h-7 flex justify-center items-center text-sm bg-green-500 text-white'>{favory?.sizes[0].price} ₼</span> :
                                        <>
                                            <span className='text-lg rounded w-full h-7 flex justify-center items-center text-sm bg-red-700 text-white line-through mr-2'>{favory?.sizes[0].price} ₼</span>
                                            <span className='text-lg rounded w-full h-7 flex justify-center items-center text-sm bg-green-500 text-white'>{favory?.sizes[0].price - favory?.sizes[0].price * Number(favory.endirim) / 100} ₼</span>
                                        </>
                                    }
                                </div>
                            </div>
                            <div onClick={(event) => { openProductModal(favory); event.stopPropagation() }} className='hidden opacity-0 absolute top-20 left-1/2 -translate-x-2/4 bg-gray-300 w-20 h-20 rounded-full md:flex justify-center items-center group-hover:opacity-90'>
                                <BsSearch />
                            </div>
                        </div>
                    ))
                    : null
                }
            </div>
        </div >
    )
}
export default Products;