import React from 'react';
import dontWork from '../assets/dontservice.png';
import { Helmet } from 'react-helmet';

const Dontwork = () => {
  return (
    <div className='select-none w-full h-screen fixed top-0 left-0 right-0 bottom-0 bg-zinc-700 z-50 flex justify-center items-center'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Servis işləmir | Light Studio Baku</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className='montserrat w-[95%] flex flex-col items-center justify-center md:w-1/2'>
        <img className='animate-bounce h-52 md:h-80' src={dontWork} alt='don"t work' />
        <h1 className='text-amber-500 text-3xl text-center md:text-4xl'>Sayt texniki səbəblərə görə işləmir😟</h1>
        <h2 className='mt-3 mb-5 text-white text-2xl text-center md:text-3xl'>Ən qısa zamanda qayıdacağıq!</h2>
        <p className='flex flex-col items-center justify-center'><span className='px-3 py-1 bg-amber-500 text-white rounded-lg'>LİGHT STUDİO BAKU</span> <span className='text-amber-500'>anlayışızın üçün sizə təşəkkür edir!!!</span></p>
      </div>
    </div>
  )
}

export default Dontwork