import React from 'react'
import AccordionProducts from '../components/Accordion';
import Productspagination from '../components/Pagination/Productspagination';
import { Helmet } from 'react-helmet';
const Products = () => {

  return (
    <div className='dark:bg-zinc-900'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Məhsullar | Light Studio Baku</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className='w-[90%] mx-auto flex flex-col md:flex-row md:justify-between'>
        <div className='w-full my-3 md:my-6 md:w-[30%]'>
          <AccordionProducts />
        </div>
        <div className='w-full md:w-[65%] my-6'>
          <Productspagination />
        </div>
      </div>
    </div>
  )
}

export default Products