import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import { Link, Navigate } from 'react-router-dom';
import { AiOutlineBuild } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import ProductsLoader from './ProductsLoader';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useLocalStorage } from 'usehooks-ts';
import { Maincontext, useContext } from '../context';

const Similiarproducts = ({ product }) => {
    const { setProductModal, setShowProductModal } = useContext(Maincontext);
    const navigate = useNavigate()
    const [favoritesLocal, setFavoritesLocal] = useLocalStorage('favorites', [])
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const checkInFavorite = (id) => {
        const unique = favoritesLocal.some((item) => id === item.id)
        if (unique) {
            return true
        } else {
            return false
        }
    }
    const favoriteClick = (e, product) => {
        if (e) {
            favoritesLocal.push(product)
            setFavoritesLocal(favoritesLocal)
        } else {
            const newArray = favoritesLocal.filter(item => item.id !== product.id)
            setFavoritesLocal(newArray)
        }
    }
    const getProductDetail = (id) => {
        navigate({
            pathname: `/productdetail/${id}`,
        });
    }
    const openProductModal = (prod) => {
        setShowProductModal(true);
        setProductModal(prod);
    }
    return (
        <div className='dark:bg-zinc-900 pt-12'>
            <div className='w-[90%] mx-auto'>
                <h2 className='text-2xl font-semibold mb-2 text-center montserrat dark:text-white'>Oxşar məhsullar</h2>
                <Link to="/" className='group flex items-center justify-center font-medium mb-5 text-center text-zinc-600 montserrat dark:text-white'><HiOutlineArrowSmRight className='rotate-90' /><HiOutlineArrowSmRight className='rotate-90' /><HiOutlineArrowSmRight className='rotate-90' /></Link>
                <Swiper
                    modules={[Pagination, Autoplay, Navigation]}
                    simulateTouch={true}
                    speed={1000}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                        1440: {
                            slidesPerView: 5,
                            spaceBetween: 30,
                        }
                    }}
                >
                    {!product.loading && product.error ? <Navigate to="/dontvork" replace={true} /> : null}
                    {product.loading &&
                        <div className='flex justify-between'>
                            <ProductsLoader />
                            <ProductsLoader className="hidden md:flex" />
                            <ProductsLoader className="hidden md:flex" />
                            <ProductsLoader className="hidden md:flex" />
                        </div>
                    }
                    {!product.loading && product.product.likeProducts && product.product.likeProducts.map((similiar) => (
                        <SwiperSlide key={similiar.id}>
                            <div onClick={() => { getProductDetail(similiar.id) }} className="w-[95%] border w-full inline-block no-underline group mx-1 md:mx-0 rounded-xl pb-1 cursor-pointer hover:shadow-lg transition-easy-in-out duration-300 dark:border-amber-400 md:w-full">
                                <div className="rounded-xl h-60 relative bg-gray-50 border-b overflow-hidden flex justify-center items-center">
                                    <img className='w-full h-full object-cover group-hover:scale-110 transition-easy-in-out duration-300' src={similiar?.images[0]} alt="img" />
                                    <div className='bg-white w-8 h-8 rounded-full flex justify-center items-center absolute top-3 right-3'>
                                        <Checkbox {...label} onClick={(event) => { favoriteClick(event.target.checked, similiar); event.stopPropagation() }} icon={<FavoriteBorder />} checkedIcon={<Favorite />} checked={checkInFavorite(similiar.id)} sx={{ '&.Mui-checked': { color: "red" } }} />
                                    </div>
                                    {similiar.new === 1 ? <div className='bg-white w-36 h-8 rounded-full flex px-4 items-center absolute top-3 left-3 bg-amber-500 text-white'><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Yeni Məhsul</span></div> : null}
                                    {Number(similiar.endirim) !== 0 && <div className={`${similiar.new === 1 ? 'top-12' : 'top-3'} bg-white w-36 h-8 rounded-full flex items-center absolute px-4 left-3 bg-rose-700 text-white`}><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Endirimli Məhsul</span></div>}
                                </div>
                                <div className='px-3 py-2'>
                                    <h1 className='border-b text-sm montserrat font-semibold dark:text-white'>{similiar?.name.length >= 30 ? similiar?.name.slice(0, 30).concat('  ...') : similiar?.name}</h1>
                                    <p className='mt-1 h-16 leading-5 text-xs overflow-x-auto pr-2 overflowScrollHide dark:text-white'>{similiar?.text}</p>
                                    <div className='my-2 w-full h-[1px] bg-gray-200'></div>
                                    <div className='flex justify-between'>
                                        <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-amber-400 text-white mr-2'>{similiar?.sizes[0].size}</span>
                                        {Number(similiar.endirim) === 0 ?
                                            <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-green-500 text-white'>{similiar?.sizes[0].price} ₼</span> :
                                            <>
                                                <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-red-700 text-white line-through mr-2'>{similiar?.sizes[0].price} ₼</span>
                                                <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-green-500 text-white'>{similiar?.sizes[0].price - similiar?.sizes[0].price * Number(similiar.endirim) / 100} ₼</span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div onClick={(event) => { openProductModal(similiar); event.stopPropagation() }} className='hidden opacity-0 absolute top-20 left-1/2 -translate-x-2/4 bg-gray-300 w-20 h-20 rounded-full md:flex justify-center items-center group-hover:opacity-90'>
                                    <BsSearch />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Similiarproducts;