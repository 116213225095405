import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import ProductsLoader from './ProductsLoader';

const Discounted = ({ categories }) => {
  return (
    <>
      {!categories.loading && categories.categories.endirimli && categories.categories.endirimli.length !== 0
        ?
        <div className='py-12 bg-zinc-800 dark:bg-zinc-900'>
          <h1 className='text-center text-3xl montserrat font-semibold text-white mb-6'>Endirimdə olan məhsullar</h1>
          <div className='w-[90%] mx-auto grid grid-cols-1 md:grid-cols-3 md:gap-8'>
            {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
            {categories.loading &&
              <div className='flex justify-between'>
                <ProductsLoader />
                <ProductsLoader className="hidden md:flex" />
                <ProductsLoader className="hidden md:flex" />
                <ProductsLoader className="hidden md:flex" />
              </div>
            }
            {!categories.loading && categories.categories.endirimli && categories.categories.endirimli.map((discountProduct) => (
              <Link key={discountProduct.id} to={`/productdetail/${discountProduct.id}`} className='cursor-pointer relative w-full my-3 h-60 border border-amber-400 flex rounded overflow-hidden group md:h-72'>
                <div className='w-[80%] transition-easy-in-out duration-300 overflow-hidden'>
                  <img className='w-full h-full object-cover group-hover:scale-105' src={discountProduct?.images[0]} alt='pic'/>
                </div>
                <div className='w-[20%] flex justify-center items-center text-center uppercase bg-amber-400'>
                  <p className='montserrat font-semibold text-red-700 leading-4'>{discountProduct?.endirim} %</p>
                </div>
                <div className='absolute left-0 bottom-0 w-3/4'>
                  <div className='flex h-8 text-white montserrat font-semibold'>
                    {discountProduct.sizes.map((size) => (
                      <p key={size.id} className='bg-zinc-900 text-[10px] w-full flex justify-center items-center'>{size.size}</p>
                    ))}
                  </div>
                  <div className='flex h-12 bg-amber-400 justify-around items-center montserrat font-semibold'>
                    {discountProduct.sizes.map((size) => (
                      <div key={size.id}>
                        <p className='text-[8px] w-full flex justify-center items-center line-through text-red-700 md:text-xs'>{size.price} AZN</p>
                        <p className='text-[8px] w-full flex justify-center items-center text-green-700 md:text-xs'>{size.price - (size.price * discountProduct.endirim / 100)} AZN</p>
                      </div>
                    ))}
                  </div>
                  <div className='flex h-6 text-white montserrat font-semibold text-[10px] justify-center items-center bg-zinc-900'>{discountProduct?.name}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        :
        null
      }
    </>
  )
}

export default Discounted;