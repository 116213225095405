import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  products: [],
  paginationCount: 0,
  productLoading: false,
};
export const fetchProductFilter = createAsyncThunk("product/fetchProductFilter", async (data) => {
  const response = await axios
    .get(`https://api.lightstudio.az/api_cat1.php${data}`);
  return response.data;
});

const productFilterSlice = createSlice({
  name: "productFilter",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchProductFilter.pending, (state) => {
      state.productLoading = true
    });
    builder.addCase(fetchProductFilter.fulfilled, (state, action) => {
      if (action.payload.products?.length === 0) {
        state.products = null
        state.paginationCount = 1
      } else {
        state.products = action.payload.products;
        state.paginationCount = action.payload.products && Math.ceil(action.payload.products[0]?.total / 12)
      }
      state.productLoading = false
    });
    builder.addCase(fetchProductFilter.rejected, (state, action) => {
      state.productLoading = false
      state.products = [];
    });
  },
});
export default productFilterSlice.reducer;