import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSearchdatas } from '../features/searchSlice';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import toast from 'react-hot-toast';
import ProductsLoader from '../components/ProductsLoader';
import errorImage from '../assets/not-found.webp';
import { useLocalStorage } from 'usehooks-ts';
import { Link } from 'react-router-dom'
import { useNavigate, Navigate } from 'react-router-dom';
import { AiOutlineBuild } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { BsSearch } from 'react-icons/bs';
import { Maincontext, useContext } from '../context';
const Searchresult = () => {
    const { setProductModal, setShowProductModal } = useContext(Maincontext);
    const searchProduct = useSelector(state => state.searchdatas);
    const dispatch = useDispatch();
    const params = useParams()
    useEffect(() => {
        dispatch(fetchSearchdatas(params.searchName))
    }, [dispatch, params.searchName])
    const navigate = useNavigate()
    const [favoritesLocal, setFavoritesLocal] = useLocalStorage('favorites', [])
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const checkInFavorite = (id) => {
        const unique = favoritesLocal.some((item) => id === item.id)
        if (unique) {
            return true
        } else {
            return false
        }
    }
    const favoriteClick = (e, product) => {
        if (e) {
            favoritesLocal.push(product)
            setFavoritesLocal(favoritesLocal)
            toast.success('Məhsul favorilərə əlavə edildi!')
        } else {
            const newArray = favoritesLocal.filter(item => item.id !== product.id)
            setFavoritesLocal(newArray)
            toast.error('Məhsul favorilərdən çıxarıldı!')
        }
    }
    const getProductDetail = (id) => {
        navigate({
            pathname: `/productdetail/${id}`,
        });
    }
    const openProductModal = (prod) => {
        setShowProductModal(true);
        setProductModal(prod);
    }
    return (
        <div className='dark:bg-zinc-900'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Axtarış nəticələri | Light Studio Baku</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            {searchProduct?.searchdatas?.products?.length === 0
                ?
                <div className='w-[90%] mx-auto py-10'>
                    <h1 className='montserrat font-semibold border-b border-amber-500 px-2 pb-2 mb-6 text-xs md:text-base dark:text-white'><span className='bg-amber-500 px-2 py-1 rounded-lg'>{params?.searchName[0].toUpperCase().concat(params?.searchName.slice(1))}</span> axtarışınıza uyğun məhsul tapılmadı!</h1>
                    <div className='w-full flex justify-center items-center'>
                        <img className='w-auto' src={errorImage} alt='errorImage' />
                    </div>
                </div>
                :
                <div className='w-[90%] mx-auto py-10'>
                    <h1 className='montserrat font-semibold border-b border-amber-500 px-2 pb-2 mb-6 text-xs md:text-base dark:text-white'>{params.searchName.length !== 0 && <span className='bg-amber-500 px-2 py-1 rounded-lg'>{params?.searchName[0]?.toUpperCase()?.concat(params?.searchName?.slice(1))}</span>} axtarışınıza uyğun {searchProduct?.searchdatas?.products?.length} məhsul tapıldı!</h1>
                    <>
                        {!searchProduct.loading && searchProduct.error ? <Navigate to="/dontvork" replace={true} /> : null}
                        {searchProduct.loading &&
                            <div className='flex justify-between'>
                                <ProductsLoader />
                                <ProductsLoader className="hidden md:flex" />
                                <ProductsLoader className="hidden md:flex" />
                                <ProductsLoader className="hidden md:flex" />
                            </div>
                        }
                        <div className='grid gap-4 grid-cols-1 md:grid-cols-4 md:gap-7'>
                            {!searchProduct.loading && searchProduct?.searchdatas?.products?.length >= 0 && searchProduct?.searchdatas?.products?.map((sell) => (
                                <div key={sell?.id}>
                                    <div onClick={() => { getProductDetail(sell?.id) }} className="relative border w-[95%] inline-block no-underline group mx-1 md:mx-0 rounded-xl pb-1 cursor-pointer hover:shadow-lg transition-easy-in-out duration-300 dark:border-amber-400 md:w-full">
                                        <div className="rounded-xl h-56 relative bg-gray-50 border-b overflow-hidden flex justify-center items-center">
                                            <img className='w-full h-full group-hover:scale-110 transition-easy-in-out duration-300' src={sell?.images?.[0]} alt="img" />
                                            <div className='bg-white w-8 h-8 rounded-full flex justify-center items-center absolute top-3 right-3'>
                                                <Checkbox {...label} onClick={(event) => { favoriteClick(event.target.checked, sell); event.stopPropagation() }} icon={<FavoriteBorder />} checkedIcon={<Favorite />} checked={checkInFavorite(sell.id)} sx={{ '&.Mui-checked': { color: "red" } }} />
                                            </div>
                                            {sell.new === 1 ? <div className='bg-white w-36 h-8 rounded-full flex px-4 items-center absolute top-3 left-3 bg-amber-500 text-white'><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Yeni Məhsul</span></div> : null}
                                            {Number(sell.endirim) !== 0 && <div className={`${sell.new === 1 ? 'top-12' : 'top-3'} bg-white w-36 h-8 rounded-full flex items-center absolute px-4 left-3 bg-rose-700 text-white`}><AiOutlineBuild /><span className='text-[10px] md:text-xs ml-2'>Endirimli Məhsul</span></div>}
                                        </div>
                                        <Link to={`/productdetail/${sell?.id}`}>
                                            <div className='px-3 py-2'>
                                                <h1 className='border-b text-sm montserrat font-semibold dark:text-white'>{sell?.name.length >= 30 ? sell?.name.slice(0, 30).concat('  ...') : sell?.name}</h1>
                                                <p className='mt-1 h-16 leading-5 text-xs overflow-x-auto pr-2 overflowScrollHide dark:text-white'>{sell?.text}</p>
                                                <div className='my-2 w-full h-[1px] bg-gray-200'></div>
                                                <div className='flex justify-between'>
                                                    <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-amber-400 text-white mr-2'>{sell?.sizes?.[0]?.size}</span>
                                                    {Number(sell.endirim) === 0 ?
                                                        <span className='text-lg rounded w-full h-7 flex justify-center items-center text-xs bg-green-500 text-white'>{sell?.sizes?.[0]?.price} ₼</span> :
                                                        <>
                                                            <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-red-700 text-white line-through mr-2'>{sell?.sizes?.[0]?.price} ₼</span>
                                                            <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-green-500 text-white'>{sell?.sizes?.[0]?.price - sell?.sizes?.[0]?.price * Number(sell?.endirim) / 100} ₼</span>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                        <div onClick={(event) => { openProductModal(sell); event.stopPropagation() }} className='hidden opacity-0 absolute top-20 left-1/2 -translate-x-2/4 bg-gray-300 w-20 h-20 rounded-full md:flex justify-center items-center group-hover:opacity-90'>
                                            <BsSearch />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                </div>
            }
        </div>
    )
}

export default Searchresult