import { configureStore } from '@reduxjs/toolkit';
import categories from './features/categoriesSlice';
import product from './features/productSlice';
import productFilter from './features/productFilterSlice'
import searchdatas from './features/searchSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    categories,
    product,
    productFilter,
    searchdatas
  },
});