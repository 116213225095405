import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  loading: false,
  categories: [],
  error: "",
};
export const fetchCategories = createAsyncThunk("categories/fetchCategories", async () => {
  const response = await axios
    .get("https://api.lightstudio.az/api_cat1.php?page=home");
  return response.data;
});
const categoriesSlice = createSlice({
  name: "category",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = false;
      state.categories = [];
      state.error = action.error.message;
    });
  },
});
export default categoriesSlice.reducer;