import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
    loading: false,
    product: [],
    error: "",
};
export const fetchProduct = createAsyncThunk("product/fetchProduct", async (id) => {
    const response = await axios
        .get(`https://api.lightstudio.az/api_cat1.php?page=product/${id}`);
    return response.data;
});
const productSlice = createSlice({
    name: "poduct",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchProduct.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProduct.fulfilled, (state, action) => {
            state.loading = false;
            state.product = action.payload;
            state.error = "";
        });
        builder.addCase(fetchProduct.rejected, (state, action) => {
            state.loading = false;
            state.product = [];
            state.error = action.error.message;
        });
    },
});
export default productSlice.reducer;