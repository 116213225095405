import React, { useEffect } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineInstagram, AiFillMail } from 'react-icons/ai';
import { BsWhatsapp, BsFillTelephoneFill } from 'react-icons/bs';
import { GoClock } from 'react-icons/go';
import { BiCurrentLocation } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../features/categoriesSlice';
import { Navigate, Link } from 'react-router-dom';

const Footer = () => {
  const categories = useSelector((state) => state.categories)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])
  return (
    <>
      {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
      {categories.loading && <li className='h-full cursor-pointer group w-[90%] mx-auto'>Zəhmət olmasa gözləyin...</li>}
      {!categories.loading && categories.categories.settings && categories.categories.settings.map((setting) => (
        <div key={setting.id} className='text-white flex flex-col justify-center items-center md:flex-row md:h-screen'>
          <div className='footerBgImg relative w-full h-full md:w-1/2 py-12 px-[5%]'>
            <h1 className='pb-4 text-2xl font-semibold'>Light Studio</h1>
            <p className='montserrat tracking-widest text-sm'>
              2015-ci ildən başladığımız çil-çıraq və bənzər məhsulların satışı ilə əlaqədar fəaliyyətimizi sadiq və məmnun müştərilərimizi güvənərək 2022-ci ildə brendləşdirdik və “Light Studio” MMC olaraq fəaliyyətə başladıq. Pərakəndə çil-çıraq satışı ilə yanaşı interyer və eksteryer dizayn sahəsində də fəaliyyətini genişləndirən şirkətimiz yaxın gələcəkdə fəaliyyət coğrafiyasını Bakıdan regionlara doğru genişləndirməyi planlaşdırır.
              İşimiz ölkəmizin aydın gələcəyinə inancımızın təzahürüdür!
            </p>
          </div>
          <div className='bg-zinc-900 w-full h-full md:w-1/2 pt-12 px-[5%] flex flex-col justify-between'>
            <div className='flex flex-col md:flex-row md:flex-wrap md:content-start'>
              <div className='montserrat md:w-1/3 h-max mb-12'>
                <h2 className='font-semibold mb-2 tracking-wider'>Bizi izlə</h2>
                <ul className='cursor-pointer'>
                  <li><a href={setting.facebook} className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><FaFacebookF className='mr-2' /><span>Facebook</span></a></li>
                  <li><a href={setting.instagram} className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><AiOutlineInstagram className='mr-2' /><span>Instagram</span></a></li>
                  <li><a href={`https://wa.me/${setting.nomre}`} className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><BsWhatsapp className='mr-2' /><span>WhatsApp  </span></a></li>
                </ul>
              </div>
              <div className='montserrat md:w-1/3 h-max mb-12'>
                <h2 className='font-semibold mb-2 tracking-wider'>Məlumat</h2>
                <ul className='cursor-pointer'>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><span>Axtarış şərtləri</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><span>Kömək</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><span>Tez-tez verilən suallar</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><Link to='/stores'>Mağaza ünvanı</Link></li>
                </ul>
              </div>
              <div className='montserrat md:w-1/3 h-max mb-12'>
                <h2 className='font-semibold mb-2 tracking-wider'>Kömək</h2>
                <ul className='cursor-pointer'>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><span>Çatdırılma</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><span>Geri dönüşlər</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><span>Karyera</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><span>Tez-tez verilən suallar</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><Link to='/contact'>Bizimlə əlaqə</Link></li>
                </ul>
              </div>
              <div className='montserrat md:min-w-1/3 h-max mb-12'>
                <h2 className='font-semibold mb-2 tracking-wider'>Bizimlə əlaqə</h2>
                <ul className='cursor-pointer'>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><BiCurrentLocation className='mr-2' /><span>{setting.unvan}</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><BsFillTelephoneFill className='mr-2' /><span>{setting.nomre}</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><GoClock className='mr-2' /><span>{setting.work}</span></li>
                  <li className='cursor-pointer flex items-center text-sm leading-7 hover:text-amber-500'><AiFillMail className='mr-2' /><span>{setting.email}</span></li>
                </ul>
              </div>
            </div>
            <p className='border-t py-6 flex justify-center'>Bütün hüquqlar qorunur! @ {new Date().getFullYear()}</p>
          </div>
        </div>
      ))}
    </>
  )
}

export default Footer;