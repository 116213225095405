import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigate } from 'react-router-dom';
import "swiper/css";
import "swiper/css/pagination";
import backgrounde from '../assets/old-black-background-grunge-texture-dark-wallpaper-blackboard-chalkboard-room-wall.jpg';
import SliderLoader from "./SliderLoader";

import { Autoplay, Pagination, Navigation } from "swiper";

function Mainslider({ categories }) {
    return (
        <>
            <Swiper
                spaceBetween={30}
                modules={[Pagination, Autoplay, Navigation]}
                simulateTouch={true}
                speed={1000}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                style={{ backgroundImage: "url(" + backgrounde + ")", backgroundPosition: 'center' }}
            >

                {!categories.loading && categories.error ? <Navigate to="/dontvork" replace={true} /> : null}
                {categories.loading &&
                    <div className="flex justify-center items-center">
                        <SliderLoader className='mt-[10rem]' />
                    </div>
                }
                {!categories.loading && categories.categories.slider && categories.categories.slider.length !== 0 ? categories.categories.slider[0].map((eachSlider) => (
                    <SwiperSlide key={eachSlider.id} className="Mainslider flex justify-center items-center">
                        <div className="flex w-full flex-col-reverse min-h-[70vh] justify-evenly items-center md:h-[76vh] md:w-[90%] md:flex-row">
                            <div className="w-[90%] text-center md:w-[45%] md:flex md:flex-col md:justify-center md:pr-20 md:pl-[60px]">
                                <h1 className="text-[28px] md:mb-2.5 md:font-semibold md:text-5xl text-amber-500">{eachSlider.text}</h1>
                                <h2 className="text-3xl md mb-3 md:text-semibold md:text-4xl text-amber-500">{eachSlider.text2}</h2>
                                <p className="text-amber-500 text-xl">
                                    {eachSlider.text3}
                                </p>
                            </div>
                            <div className="w-3/4 h-[35vh] md:h-[60vh] md:w-[45%] flex justify-center items-center">
                                <img className="w-full h-full rounded-2xl" src={eachSlider.url} alt='pic' />
                            </div>
                        </div>
                    </SwiperSlide>
                )) : null}
            </Swiper>
        </>
    );
}
export default Mainslider;