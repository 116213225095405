import React from 'react';
import { Helmet } from 'react-helmet';
const Aboutcompany = () => {
  return (
    <div className='flex flex-col bg-zinc-100 montserrat'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Haqqımızda | Light Studio Baku</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className='w-full h-60 bg-amber-500 flex justify-center items-center dark:bg-zinc-900'>
        <h1 className='font-semibold text-5xl text-white'>Haqqımızda</h1>
      </div>
      <div className='w-[90%] mx-auto -mt-16 pb-12 flex flex-col items-center md:w-3/5'>
        <div className='w-full min-h-[25vh] bg-white rounded-lg p-8 mb-8 dark:bg-zinc-800'>
          <h1 className='text-2xl font-semibold dark:text-amber-500'>Light Studio</h1>
          <p className='font-medium mt-3 dark:text-white'>
            2015-ci ildən başladığımız çil-çıraq və bənzər məhsulların satışı ilə əlaqədar fəaliyyətimizi sadiq və məmnun müştərilərimizə güvənərək 2022-ci ildə brendləşdirdik və “Light Studio” MMC olaraq fəaliyyətə başladıq. Pərakəndə çil-çıraq satışı ilə yanaşı interyer və eksteryer dizayn sahəsində də fəaliyyətini genişləndirən şirkətimiz yaxın gələcəkdə fəaliyyət coğrafiyasını Bakıdan regionlara doğru genişləndirməyi planlaşdırır.
            İşimiz ölkəmizin aydın gələcəyinə inancımızın təzahürüdür!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Aboutcompany