import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineBuild } from 'react-icons/ai';
import Pagination from './Pagination'
import ProductSkeleton from './productSkeleton'
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useLocalStorage } from 'usehooks-ts';
import toast from 'react-hot-toast';
import { BsSearch } from 'react-icons/bs';
import { Maincontext, useContext } from '../../context';
const Productspagination = () => {
  const { setProductModal, setShowProductModal } = useContext(Maincontext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const products = useSelector((state) => state.productFilter.products)
  const productLoading = useSelector((state) => state.productFilter.productLoading);
  const [selectOption, setSelectOption] = useState(searchParams.get('type') || 6);
  const [favoritesLocal, setFavoritesLocal] = useLocalStorage('favorites', [])
  useEffect(() => {
    setSelectOption(searchParams.get('type'))
  }, [searchParams])
  function setSelectValue(data) {
    const sp = new URLSearchParams(location.search)
    setSelectOption(data.target.value)
    sp.set("type", data.target.value)
    setSearchParams(sp)
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const checkInFavorite = (id) => {
    const unique = favoritesLocal.some((item) => id === item.id)
    if (unique) {
      return true
    } else {
      return false
    }
  }
  const favoriteClick = (e, product) => {
    if (e) {
      favoritesLocal.push(product)
      setFavoritesLocal(favoritesLocal)
      toast.success('Məhsul favorilərə əlavə edildi!')
    } else {
      const newArray = favoritesLocal.filter(item => item.id !== product.id)
      setFavoritesLocal(newArray)
      toast.error('Məhsul favorilərdən çıxarıldı!')
    }
  }
  const getProductDetail = (id) => {
    navigate({
      pathname: `/productdetail/${id}`,
    });
  }
  const openProductModal = (prod) => {
    setShowProductModal(true);
    setProductModal(prod);
  }
  return (
    <div className='flex flex-col'>
      <div className='flex justify-between items-center h-12 md:h-14'>
        <h3 className='montserrat font-semibold text-sm md:text-2xl text-zinc-800 dark:text-white'>{products ? products.length : 0} məhsul tapıldı</h3>
        <select onChange={setSelectValue} value={selectOption ? selectOption : 6} className='border border-zinc-300 text-xs appearance-none w-48 h-full outline-0 text-center bg-gray-100 rounded-lg font-medium montserrat md:text-base md:w-60'>
          <option value="6">Yeni gələn məhsullar</option>
          <option value="5">Ən çox baxılanlar</option>
          <option value="1">A-dan Z-yə</option>
          <option value="2">Z-dən A-ya</option>
          <option value="3">Ucuzdan bahaya</option>
          <option value="4">Bahadan ucuza</option>
        </select>
      </div>
      <div className='w-full h-[1px] bg-zinc-300 my-3' />
      <div className='grid gap-x-1 gap-y-4 grid-cols-2 md:grid-cols-3 md:gap-4'>
        {!productLoading ?
          products?.map(item => (
            <div onClick={() => { getProductDetail(item.id) }} key={item.id} className="relative border inline-block no-underline group mx-1 md:mx-0 rounded-xl pb-1 cursor-pointer hover:shadow-lg transition-easy-in-out duration-300 dark:border-amber-400">
              <div className="rounded-xl w-full h-40 relative bg-gray-50 border-b overflow-hidden flex justify-center items-center md:h-56">
                <img className='w-full h-full md:h-64 group-hover:scale-110 transition-easy-in-out duration-300' src={item.images[0]} alt="img" />
                <div className='border bg-white w-8 h-8 rounded-full flex justify-center items-center absolute top-3 right-3'>
                  <Checkbox {...label} onClick={(event) => { favoriteClick(event.target.checked, item); event.stopPropagation() }} icon={<FavoriteBorder sx={{ fontSize: '16px' }} />} checkedIcon={<Favorite sx={{ fontSize: '16px' }} />} checked={checkInFavorite(item.id)} sx={{ '&.Mui-checked': { color: "red" } }} />
                </div>
                {item.new === 1 ? <div className='bg-white w-20 md:w-36 h-6 rounded-full flex px-2 items-center absolute top-3 left-3 bg-amber-500 text-white'><AiOutlineBuild /><span className='text-[6px] md:text-[10px] md:text-xs ml-1'>Yeni Məhsul</span></div> : null}
                {Number(item.endirim) !== 0 && <div className={`${item.new === 1 ? 'top-10' : 'top-3'} bg-white w-20 md:w-36 h-6 rounded-full flex items-center absolute px-2 left-3 bg-rose-700 text-white`}><AiOutlineBuild /><span className='text-[6px] md:text-[10px] md:text-xs ml-1'>Endirimli</span></div>}

              </div>
              <div className='px-3 py-2'>
                <h1 className='montserrat text-xs md:text-base mb-1 font-semibold dark:text-white'>{item?.name.length >= 20 ? item?.name.slice(0, 20).concat('  ...') : item?.name}</h1>
                <p className='h-16 leading-5 text-[10px] dark:text-white h-12 overflow-x-auto pr-2 overflowScrollHide md:text-sm'>{item?.text}</p>
                <div className='my-2 w-full h-[1px] bg-gray-200'></div>
                <div className='flex flex-col md:flex-row md:justify-between'>
                  <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-amber-400 text-white mr-2 mb-1 md:mb-0'>{item.sizes[0].size}</span>
                  {Number(item.endirim) === 0 ?
                    <>
                      <span className='rounded w-full h-16 flex justify-center items-center text-xs bg-green-500 text-white mb-1 md:mb-0 md:h-7'>{item?.sizes[0].price} ₼</span>
                    </>
                    :
                    <>
                      <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-red-700 text-white line-through mr-2 mb-1 md:mb-0'>{item?.sizes[0].price} ₼</span>
                      <span className='rounded w-full h-7 flex justify-center items-center text-xs bg-green-500 text-white mb-1 md:mb-0'>{item?.sizes[0].price - item?.sizes[0].price * Number(item.endirim) / 100} ₼</span>
                    </>
                  }
                </div>
              </div>
              <div onClick={(event) => { openProductModal(item); event.stopPropagation() }} className='hidden opacity-0 absolute top-20 left-1/2 -translate-x-2/4 bg-gray-300 w-20 h-20 rounded-full md:flex justify-center items-center group-hover:opacity-90'>
                <BsSearch />
              </div>
            </div>
          ))
          :
          Array(6).fill().map((item, index) => (
            <ProductSkeleton key={index} />
          ))
        }
      </div>
      {
        !products &&
        <div className='montserrat font-semibold w-full h-24 flex justify-center items-center dark:text-white'>
          <p>Məhsul tapılmadı!</p>
        </div>
      }
      <Pagination />
    </div>
  );
}
export default Productspagination;