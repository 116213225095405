import React from "react";
import Home from "./pages/Home";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import Deliverymodal from "./components/Deliverymodal";
import Error from "./pages/Error";
import Products from './pages/Products';
import Productdetail from "./pages/Productdetail";
import Stores from './pages/Stores';
import Aboutcompany from './pages/Aboutcompany';
import Favorites from "./pages/Favorites";
import GotoWhatsApp from "./components/GotoWhatsApp";
import Dontwork from './pages/Dontwork'
import Searchresult from "./pages/Searchresult";
import Contact from './pages/Contact';
import { Routes, Route } from "react-router-dom";
import { Maincontext } from "./context";
import { Toaster } from 'react-hot-toast'
import ProductModal from "./components/ProductModal";
function App() {
  const [sidebar, setSidebar] = React.useState(false);
  const [theme, setTheme] = React.useState('light');
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [productModal, setProductModal] = React.useState({});
  const [favorites, setFavorites] = React.useState(JSON.parse(localStorage.getItem("favorites")) || []);
  React.useEffect(() => {
    document.documentElement.classList.toggle('dark');
  }, [theme])
  const data = {
    sidebar, setSidebar, theme, setTheme, favorites, setFavorites, showProductModal, setShowProductModal, productModal, setProductModal
  }
  return (
    <Maincontext.Provider value={data}>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
      {showProductModal ? <ProductModal /> : null}
      <Deliverymodal />
      <Header />
      <Sidebar />
      <GotoWhatsApp />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mehsullar" element={<Products />} />
        <Route path="/productdetail/:id" element={<Productdetail />} />
        <Route path="/stores" element={<Stores />} />
        <Route path="/aboutcompany" element={<Aboutcompany />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/search/:searchName" element={<Searchresult />} />
        <Route path="/dontvork" element={<Dontwork />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Maincontext.Provider>
  );
}

export default App;